import {NFCe} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getNFCe(context, id) {
        return NFCe.get({id}).then((response) => {
            return response.data;
        });
    },
    updateNFCe(context, item) {
        return NFCe.update(item).then((response) => {
            return response.data;
        });
    },
    generateDANFCe(context, item) {
        return NFCe.generateDANFCe(item).then((response) => {
            return response.data;
        });
    },
    issueNFCe(context, item) {
        return NFCe.issueNFCe(item).then((response) => {
            return response.data;
        });
    },
    cancelNFCe(context, item) {
        return NFCe.cancelNFCe(item).then((response) => {
            return response.data;
        });
    },
    listNFCeInutilizadas(context, item) {
        return NFCe.listNFCeInutilizadas(item).then((response) => {
            return response.data;
        });
    },
    inutilizaNFCe(context, item) {
        return NFCe.inutilizaNFCe(item).then((response) => {
            return response.data;
        });
    },
    generateCartReturn(context, item) {
        return NFCe.generateCartReturn(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
