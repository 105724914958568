import {Tickets} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getTickets(context, pagination) {
        return Tickets.getTickets(pagination).then((response) => {
            return response.data;
        });
    },
    getTicket(context, id) {
        return Tickets.get({id}).then((response) => {
            return response.data;
        });
    },
    createTicket(context, item) {
        return Tickets.create(item).then((response) => {
            return response.data;
        });
    },
    updateTicket(context, item) {
        return Tickets.update(item).then((response) => {
            return response.data;
        });
    },
    destroyTicket(context, item) {
        return Tickets.destroy(item).then((response) => {
            return response.data;
        });
    },
    getTicketField(context, item) {
        return Tickets.getTicketField(item).then((response) => {
            return response.data;
        });
    },
    updateTicketStatus(context, item) {
        return Tickets.updateTicketStatus(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
