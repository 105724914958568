import {Sales} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSales(context, pagination) {
        return Sales.getSales(pagination).then((response) => {
            return response.data;
        });
    },
    getSale(context, id) {
        return Sales.get({id}).then((response) => {
            return response.data;
        });
    },
    createSale(context, item) {
        return Sales.create(item).then((response) => {
            return response.data;
        });
    },
    updateSale(context, item) {
        return Sales.update(item).then((response) => {
            return response.data;
        });
    },
    cancelSale(context, item) {
        return Sales.cancelSale(item).then((response) => {
            return response.data;
        });
    },
    sendEmailSale(context, item) {
        return Sales.sendEmailSale(item).then((response) => {
            return response.data;
        });
    },
    getSaleStatistics(context, item) {
        return Sales.getSaleStatistics(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
