import {Departments} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getDepartments(context, pagination) {
        return Departments.getDepartments(pagination).then((response) => {
            return response.data;
        });
    },
    getDepartment(context, id) {
        return Departments.get({id}).then((response) => {
            return response.data;
        });
    },
    createDepartment(context, item) {
        return Departments.create(item).then((response) => {
            return response.data;
        });
    },
    updateDepartment(context, item) {
        return Departments.update(item).then((response) => {
            return response.data;
        });
    },
    destroyDepartment(context, item) {
        return Departments.destroy(item).then((response) => {
            return response.data;
        });
    },
    getDepartmentField(context, item) {
        return Departments.getDepartmentField(item).then((response) => {
            return response.data;
        });
    },
    getDepartmentServiceField(context, item) {
        return Departments.getDepartmentServiceField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
