import {Cashiers} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCashiers(context, pagination) {
        return Cashiers.getCashiers(pagination).then((response) => {
            return response.data;
        });
    },
    getCashier(context, id) {
        return Cashiers.get({id}).then((response) => {
            return response.data;
        });
    },
    createCashier(context, item) {
        return Cashiers.create(item).then((response) => {
            return response.data;
        });
    },
    updateCashier(context, item) {
        return Cashiers.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCashier(context, item) {
        return Cashiers.destroy(item).then((response) => {
            return response.data;
        });
    },
    getUserCashier(context, item) {
        return Cashiers.getUserCashier(item).then((response) => {
            return response.data;
        });
    },
    createSuprimentoCashier(context, item) {
        return Cashiers.createSuprimentoCashier(item).then((response) => {
            return response.data;
        });
    },
    createSangriaCashier(context, item) {
        return Cashiers.createSangriaCashier(item).then((response) => {
            return response.data;
        });
    },
    createFechamentoCashier(context, item) {
        return Cashiers.createFechamentoCashier(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
