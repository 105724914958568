import {SlipSchedules} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSlipSchedules(context, pagination) {
        return SlipSchedules.getSlipSchedules(pagination).then((response) => {
            return response.data;
        });
    },
    getSlipSchedule(context, id) {
        return SlipSchedules.get({id}).then((response) => {
            return response.data;
        });
    },
    createSlipSchedule(context, item) {
        return SlipSchedules.create(item).then((response) => {
            return response.data;
        });
    },
    updateSlipSchedule(context, item) {
        return SlipSchedules.update(item).then((response) => {
            return response.data;
        });
    },
    destroySlipSchedule(context, item) {
        return SlipSchedules.destroy(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
