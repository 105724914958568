import {BankAccounts} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getBankAccounts(context, pagination) {
        return BankAccounts.getBankAccounts(pagination).then((response) => {
            return response.data;
        });
    },
    getBankAccount(context, id) {
        return BankAccounts.get({id}).then((response) => {
            return response.data;
        });
    },
    createBankAccount(context, item) {
        return BankAccounts.create(item).then((response) => {
            return response.data;
        });
    },
    updateBankAccount(context, item) {
        return BankAccounts.update(item).then((response) => {
            return response.data;
        });
    },
    destroyBankAccount(context, item) {
        return BankAccounts.destroy(item).then((response) => {
            return response.data;
        });
    },
    getBankAccountField(context, item) {
        return BankAccounts.getBankAccountField(item).then((response) => {
            return response.data;
        });
    },
    getBankField(context, item) {
        return BankAccounts.getBankField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
