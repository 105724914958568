import {Carts} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCarts(context, pagination) {
        return Carts.getCarts(pagination).then((response) => {
            return response.data;
        });
    },
    getCart(context, id) {
        return Carts.get({id}).then((response) => {
            return response.data;
        });
    },
    createCart(context, item) {
        return Carts.create(item).then((response) => {
            return response.data;
        });
    },
    updateCart(context, item) {
        return Carts.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCart(context, item) {
        return Carts.destroy(item).then((response) => {
            return response.data;
        });
    },
    countCart(context, item) {
        return Carts.countCart(item).then((response) => {
            return response.data;
        });
    },
    getListAvailableTimes(context, item) {
        return Carts.getListAvailableTimes(item).then((response) => {
            return response.data;
        });
    },
    cartSaleDraft(context, item) {
        return Carts.cartSaleDraft(item).then((response) => {
            return response.data;
        });
    },

    createCartPublic(context, item) {
        return Carts.createCartPublic(item).then((response) => {
            return response.data;
        });
    },
    getListAvailableTimesPublic(context, item) {
        return Carts.getListAvailableTimesPublic(item).then((response) => {
            return response.data;
        });
    },
    cancelCartPublic(context, item) {
        return Carts.cancelCartPublic(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
