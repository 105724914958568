import {Analytics} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAnalytics(context, pagination) {
        return Analytics.getAnalytics(pagination).then((response) => {
            return response.data;
        });
    },
    getSaleAnalytics(context, pagination) {
        return Analytics.getSaleAnalytics(pagination).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
