import {NFe} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getNFe(context, id) {
        return NFe.get({id}).then((response) => {
            return response.data;
        });
    },
    updateNFe(context, item) {
        return NFe.update(item).then((response) => {
            return response.data;
        });
    },
    generateDANFe(context, item) {
        return NFe.generateDANFe(item).then((response) => {
            return response.data;
        });
    },
    issueNFe(context, item) {
        return NFe.issueNFe(item).then((response) => {
            return response.data;
        });
    },
    cancelNFe(context, item) {
        return NFe.cancelNFe(item).then((response) => {
            return response.data;
        });
    },
    listNFeInutilizadas(context, item) {
        return NFe.listNFeInutilizadas(item).then((response) => {
            return response.data;
        });
    },
    inutilizaNFe(context, item) {
        return NFe.inutilizaNFe(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
