<template>
  <v-app>
    <div v-if="showLogout">
      <v-progress-linear :indeterminate="true" class="my-0"
                         color="white"
                         height="2"></v-progress-linear>
    </div>

    <v-toolbar :extension-height="130"
               app
               class="background-toolbar"
               color="primary lighten-1"
               dark
               dense
               extended
    >
      <template v-if="authenticated">
        <v-toolbar-side-icon @click="menuDrawer()" v-if="!$vuetify.breakpoint.lgAndUp"></v-toolbar-side-icon>

        <v-toolbar-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="$router.push({name: 'dashboard'})" icon v-on="on">
                <v-icon :class="($route.name == 'dashboard' ? 'white--text' : '')">dashboard</v-icon>
              </v-btn>
            </template>
            <span>Página Inicial</span>
          </v-tooltip>

          {{ titlePage }}

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click.ctrl="clearFiltersDefault" @click="reloadPage" icon style="margin-left: -2px"
                     v-show="titlePage" v-on="on">
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
            <span>Atualizar Página</span>
          </v-tooltip>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="printTags" icon
                   v-show="titlePage" v-on="on">
              <v-icon>style</v-icon>
            </v-btn>
          </template>
          <span>Imprimir etiquetas</span>
        </v-tooltip>

        <v-menu v-model="menu2"
                :close-on-content-click="false"
                :nudge-width="500"
                left
        >
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-badge color="red" overlap>
                <v-icon>music_note</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-card elevation="0">
            <v-card-text>
              <iframe src="https://radio.burguesinhabrechochic.com.br/" frameborder="0" width="100%"
                      height="600px"></iframe>
              <v-spacer class="mb-1"></v-spacer>
              <div class="text-xs-right">
                <v-btn @click="menu2 = false;" color="grey darken-2" class="no-margin-right" flat>Fechar</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-tooltip bottom>
          <v-btn @click.stop="notificationsMenu = !notificationsMenu" icon slot="activator">
            <v-badge color="red" overlap>
              <span slot="badge" v-if="notifications.total">{{ notifications.total }}</span>
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
          <span v-show="!notifications.total">Não há notificações</span>
          <span
              v-show="notifications.total">Há {{
              notifications.total > 1 ? `${notifications.total} notificações` : `${notifications.total} notificação`
            }}</span>
        </v-tooltip>

        <v-menu :close-on-content-click="false"
                :nudge-width="150"
                button
                left
                v-model="menu"
        >
          <v-btn icon slot="activator">
            <v-icon>more_vert</v-icon>
          </v-btn>
          <v-card>
            <v-list>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ user.name }}</v-list-tile-title>
                  <v-list-tile-sub-title style="min-width: 100px;">
                    {{ user.email }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action></v-list-tile-action>
              </v-list-tile>
            </v-list>
            <v-divider></v-divider>
            <v-list>
              <v-list-tile :key="item.to" :to="{name: item.to}" @click="menu = false"
                           v-for="item in menuUser">
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="menu = false; logout();" color="red" flat>Sair</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-toolbar>

    <menu-navigation :authenticated="menuCheck"
                     :company="company"
                     :user="user"
                     ref="referenceMenu">
    </menu-navigation>

    <v-navigation-drawer :right="true"
                         app
                         fixed
                         temporary
                         v-model="notificationsMenu"
                         width="300">
      <v-toolbar class="primary" dark flat prominent>
        <v-toolbar-title>Notificações</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click.stop="notificationsMenu = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list two-line>
        <v-subheader>Todas notificações</v-subheader>

        <template v-for="(notification, index) in notifications.items">
          <v-list-tile :key="index" :to="{name: notification.link}">
            <v-list-tile-content>
              <v-list-tile-title v-html="notification.title"></v-list-tile-title>
              <v-list-tile-sub-title v-html="notification.subTitle"></v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="notification.info"><small>{{ notification.info }}</small>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-divider
              v-if="(index + 1) < notifications.items.length"
              :key="(index + 1000)"
          ></v-divider>
        </template>

      </v-list>
    </v-navigation-drawer>

    <main>
      <v-content>
        <v-container fluid grid-list-md>
          <v-slide-y-transition mode="out-in">
            <router-view @getNotifications="getNotifications"
                         @getCommunications="getCommunications"
                         @showCommunications="showCommunications"
                         @menuDrawer="menuDrawer"
                         @titlePage="updateTitlePage"
                         @refreshCountSchedules="refreshCountSchedules"
                         @showUserBadge="showUserBadge"
                         :company="company"
            ></router-view>
          </v-slide-y-transition>
        </v-container>

        <v-dialog v-model="dialogConfirm" persistent max-width="290">
          <v-card>
            <v-card-text>
              <div v-html="confirmMessage"></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click="eventConfirm()">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogExpired" persistent max-width="400">
          <v-card>
            <v-card-text>
              <div v-html="textExpired"></div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-grey darken-4" flat @click="closeExpired">Não</v-btn>
              <v-btn color="primary" flat :loading="loadingExpired" @click="confirmExpired">Sim</v-btn>
            </v-card-actions>
          </v-card>

          <v-dialog v-model="communication.dialog" persistent max-width="1000">
            <v-card>
              <v-card-text>
                <h1 class="headline">
                  {{ communication.item.assunto }}
                </h1>
                <span class="grey--text">Abaixo segue o comunicado com suas informações.</span>
              </v-card-text>

              <v-card-text>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div class="ql-container ql-snow pa-2">
                      <div class="ql-editor ql-disabled" data-gramm="false" contenteditable="false"
                           v-html="communication.item.descricao">
                      </div>
                    </div>
                  </v-flex>

                  <v-flex xs12>
                    <v-layout row wrap>
                      <v-flex xs12 v-for="(item, index) in communication.item.files" v-bind:key="index">
                        <div class="pa-2 grey lighten-4">
                          <div>
                            {{ item.identification }}
                          </div>

                          <v-spacer></v-spacer>

                          <v-chip small label color="blue white--text" class="no-margin-left cursor-pointer"
                                  @click="openFile(item)">
                            {{ item.name }} &nbsp;
                            <v-icon>attachment</v-icon>
                          </v-chip>
                        </div>

                        <v-spacer class="mb-2"></v-spacer>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-grey darken-4" flat @click="communication.dialog = false"
                       v-if="!communication.item.bloqueio_sistema || communication.preview">Fechar
                </v-btn>
                <v-btn color="green white--text" :loading="communication.loading" @click="confirmCommunication"
                       v-if="!communication.preview">
                  Aceitar &nbsp;
                  <v-icon>check</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-dialog>

        <print-tag-component ref="tagComponent"></print-tag-component>
      </v-content>
    </main>

    <v-footer app
              class="white--text"
              color="secondary pa-3"
              v-if="!authenticated">
      <v-spacer></v-spacer>
      <v-layout justify-center row wrap>
        <v-flex py-2 text-xs-right white--text xs12>
          <small>
            Todos os direitos reservados <span>&copy;</span> {{ new Date().getFullYear() }}
          </small>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import store from './store/store';
import LocalStorage from "./services/localStorage";
import MenuNavigation from './components/app/MenuNavigation';
import PrintTagComponent from "@/components/tags/components/PrintTagComponent.vue";

export default {
  components: {
    PrintTagComponent,
    MenuNavigation,
  },
  data() {
    return {
      notificationsMenu: false,
      titlePage: '',
      showLogout: false,
      menuUser: [],
      menu: false,
      menu2: false,
      message: false,
      hints: true,
      trial: 0,
      notifications: {
        total: 0,
        items: []
      },

      dialogExpired: false,
      loadingExpired: false,
      textExpired: '',

      dialogConfirm: false,
      confirmMessage: '',

      communication: {
        dialog: false,
        loading: false,
        preview: false,
        items: [],
        item: {},
      },

      company: {}
    }
  },
  watch: {
    $route(to, from) {
      let variants = [
        'login',
        'register',
        'reset.password',
      ];

      if (variants.includes(from.name) && this.authenticated) {
        store.commit('menuCheck');
      }

      this.titlePage = '';
    },
    authenticated: function (auth) {
      if (auth) {
        this.getNotifications();
        this.getCommunications();
        this.getInformation();
        this.refreshCountSchedules();
        this.showUserBadge();
        this.getCompany();

        this.chat();
      }
    },
    user() {
    },
    'communication.dialog'(dialog) {
      if (!dialog) {
        setTimeout(() => this.showCommunications(), 500);
      }
    }
  },
  mounted: function () {
    let self = this;
    window.addEventListener('storage', function (event) {
      if (event.key === 'token' && !event.newValue) {
        store.dispatch('clearAuth');
        self.$router.push({name: 'login'});
      }
    });

    /**
     * clearFiltersDefault
     */
    if (LocalStorage.get('version')) {
      if (LocalStorage.get('version') !== process.env.VUE_APP_VERSION) {
        this.clearFiltersDefault();

        store.dispatch('getUser', this.user.id)
            .then((response) => {
              let user = response.data;
              if (user) store.commit('setUser', user);
            });
      }
    } else {
      this.clearFiltersDefault();
    }
    LocalStorage.set('version', process.env.VUE_APP_VERSION);

    if (this.authenticated) {
      this.getNotifications();
      this.getCommunications();
      this.getInformation();
      this.refreshCountSchedules();
      this.showUserBadge();
      this.getCompany();

      if (this.user && this.user.id) {
        this.chat();

        setTimeout(() => {
          if (window.jivo_api) {
            window.jivo_api.setContactInfo({
              "name": this.user.name,
              "email": this.user.email
            });
          }
        }, 3000)
      }
    }
  },
  computed: {
    authenticated() {
      return store.state.auth.check;
    },
    menuCheck() {
      return store.state.auth.menuCheck;
    },
    user() {
      let user = store.state.auth.user;

      return user ? user : '';
    }
  },
  methods: {
    chat() {
      (function () {
        let widget_id = '0jKc23YJAd';
        let d = document;
        let w = window;

        function l() {
          let s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = '//code.jivosite.com/script/widget/' + widget_id;
          s.setAttribute('id', 'jivochat');
          let ss = document.getElementsByTagName('script')[0];
          ss.parentNode.insertBefore(s, ss);
        }

        if (d.readyState == 'complete') {
          l();
        } else {
          if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    },
    reloadPage() {
      window.location.reload()
    },
    updateTitlePage(titlePage) {
      this.titlePage = titlePage;
    },
    menuDrawer(force = false, status = false) {
      if (force) {
        return this.$refs.referenceMenu.drawer = status;
      }

      return this.$refs.referenceMenu.drawer = !this.$refs.referenceMenu.drawer;
    },
    getNotifications() {
      store.dispatch('getUserNotifications')
          .then((response) => {
            response = response.data;

            this.notifications = {
              total: response.total,
              items: response.items
            };
          })
    },
    getCommunications() {
      store.dispatch('getCompanyCommunication')
          .then((response) => {
            response = response.data;

            if (response) {
              this.communication.items = response;
              this.showCommunications();
            }
          })
    },
    showCommunications(items = false) {
      if (items) {
        this.communication.preview = true;
      } else {
        items = this.communication.items;
      }

      for (let communication of items) {
        let item = communication;
        this.communication.item = JSON.parse(JSON.stringify(item));

        const index = this.communication.items.indexOf(item);
        this.communication.items.splice(index, 1);

        this.communication.dialog = true;

        return;
      }
    },

    getInformation() {
    },
    logout() {
      this.showLogout = true;

      let goToLogin = () => {
        LocalStorage.remove('token');
        LocalStorage.remove('refresh_token');
        store.dispatch('clearAuth');

        this.showLogout = false;
        this.$router.push({name: 'login'});
      };

      store.dispatch('logout')
          .then(goToLogin)
          .catch(goToLogin);
    },
    clearFiltersDefault() {
      for (let item in localStorage) {
        if (item.match(/search_/) ||
            item.match(/headers_/)) {
          localStorage.removeItem(item);
        }
      }
    },

    closeExpired() {
      this.logout();
    },
    async confirmExpired() {
      this.loadingExpired = true;

      await store.dispatch('requestAccessRenewalExternalUsers').then(() => {
        this.loadingExpired = false;

        this.confirmMessage = 'A solicitação foi registrada e será avaliada. <br> Caso a solicitação seja aprovada, será enviado o e-mail informando a liberação ao sistema.';
        this.dialogConfirm = true;
      });
    },
    eventConfirm() {
      this.logout();
    },

    refreshCountSchedules() {
      store.dispatch('countCart')
          .then((response) => {
            let List1 = JSON.parse(JSON.stringify(this.$refs.referenceMenu.List1));
            let List2 = JSON.parse(JSON.stringify(this.$refs.referenceMenu.List2));

            const queue = List1.find(item => item.link === 'queues.index');
            if (queue) queue.badge = response.countQueue;

            const schedule = List1.find(item => item.link === 'schedules.index');
            if (schedule) schedule.badge = response.countSchedule;

            const draft = List2.find(item => item.link === 'sales-draft.index');
            if (draft) draft.badge = response.countSaleDraft;

            this.$refs.referenceMenu.List1 = List1;
            this.$refs.referenceMenu.List2 = List2;
          });
    },

    showUserBadge() {
      store.dispatch('getUserBadge')
          .then((response) => {
            let List5 = JSON.parse(JSON.stringify(this.$refs.referenceMenu.List5));

            const slip = List5.find(item => item.link === 'slips.index');
            if (slip) slip.badge = response.countSlips;

            const supply = List5.find(item => item.link === 'supply-orders.index');
            if (supply) supply.badge = response.countSupplyOrders;

            const ticket = List5.find(item => item.link === 'tickets.index');
            if (ticket) ticket.badge = response.countTickets;

            this.$refs.referenceMenu.List5 = List5
          });
    },

    async confirmCommunication() {
      this.communication.loading = true;

      let data = {
        communication_id: this.communication.item.id
      };

      await store.dispatch('setCompanyCommunication', data)
          .then(() => {
            this.communication.dialog = false;
          })
          .catch(() => {
          });

      this.communication.loading = false;
    },

    openFile(item) {
      if (item.file) window.open(item.file, '_blank');
    },

    async printTags() {
      this.$refs.tagComponent.dialogTag = true;
      this.$refs.tagComponent.company = this.company;
    },

    async getCompany() {
      await store.dispatch('getConfig', {})
          .then((response) => {
            if (response.company) this.company = response.company;
          });
    }
  }
}
</script>

<style>
@import '@/assets/styles/app.css';
</style>
