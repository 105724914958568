import Vue from 'vue'
import VueResource from 'vue-resource'
import JwtToken from './jwtToken'
import store from '../store/store';
import LocalStorage from '../services/localStorage'
import router from '../router';

Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
    let ignore = [
        'oauth/token',
        'api/oauth/register',
    ];

    if (!ignore.includes(request.url)) {
        request.headers.set('Authorization', JwtToken.getAuthorizationHeader());
    }

    next();
});

let logout = () => {
    LocalStorage.remove('token');
    LocalStorage.remove('refresh_token');
    store.dispatch('clearAuth');

    if (router.currentRoute.name !== 'login') router.push({name: 'login'});
};

Vue.http.interceptors.push((request, next) => {
    next((response) => {
        switch (response.status) {
            // case 0:
            //     break;
            case 401:
                if (!JwtToken.refresh_token) {
                    logout();
                }
                if (request.url !== 'oauth/token' && JwtToken.refresh_token) {
                    return JwtToken.refreshToken()
                        .then(() => {
                            return Vue.http(request);
                        })
                        .catch(() => {
                            logout();
                        });
                }
                break;
            case 403:
                // if (response.data) {
                // }
                break;
            default:
                if (response.data) {
                    if (response.data.message) {
                        if (response.data.message.match(/Expired token/) ||
                            response.data.message.match(/User not found/)) {
                            logout();
                        }
                    }
                }
        }
    });
});
