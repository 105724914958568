import {Products} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getProducts(context, pagination) {
        return Products.getProducts(pagination).then((response) => {
            return response.data;
        });
    },
    getProduct(context, id) {
        return Products.get({id}).then((response) => {
            return response.data;
        });
    },
    createProduct(context, item) {
        return Products.create(item).then((response) => {
            return response.data;
        });
    },
    updateProduct(context, item) {
        return Products.update(item).then((response) => {
            return response.data;
        });
    },
    destroyProduct(context, item) {
        return Products.destroy(item).then((response) => {
            return response.data;
        });
    },
    getProductField(context, item) {
        return Products.getProductField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
