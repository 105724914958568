import {AccountsReceivable} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAccountsReceivable(context, pagination) {
        return AccountsReceivable.getAccountsReceivable(pagination).then((response) => {
            return response.data;
        });
    },
    getAccountReceivable(context, id) {
        return AccountsReceivable.get({id}).then((response) => {
            return response.data;
        });
    },
    createAccountReceivable(context, item) {
        return AccountsReceivable.create(item).then((response) => {
            return response.data;
        });
    },
    updateAccountReceivable(context, item) {
        return AccountsReceivable.update(item).then((response) => {
            return response.data;
        });
    },
    destroyAccountReceivable(context, item) {
        return AccountsReceivable.destroy(item).then((response) => {
            return response.data;
        });
    },
    paymentAccountReceivable(context, item) {
        return AccountsReceivable.paymentAccountReceivable(item).then((response) => {
            return response.data;
        });
    },
    cancelAccountReceivable(context, item) {
        return AccountsReceivable.cancelAccountReceivable(item).then((response) => {
            return response.data;
        });
    },
    reverseAccountReceivable(context, item) {
        return AccountsReceivable.reverseAccountReceivable(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
