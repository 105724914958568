import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/default', name: 'default', component: () => import('@/components/DefaultPage'),
            meta: {auth: true},
        },
        {
            path: '/', name: 'login', component: () => import('@/components/auth/LoginPage'),
            meta: {auth: false},
        },
        {
            path: '/dashboard', name: 'dashboard', component: () => import('@/components/DashboardPage'),
            meta: {auth: true},
        },
        {
            path: '/register', name: 'register', component: () => import('@/components/auth/RegisterPage'),
            meta: {auth: false},
        },
        {
            path: '/reset', name: 'reset', component: () => import('@/components/auth/ResetPage'),
            meta: {auth: false},
        },
        {
            path: '/reset/password/:token',
            name: 'reset.password',
            component: () => import('@/components/auth/ResetPasswordPage'),
            meta: {auth: false},
        },
        {
            path: '/profile', name: 'profile', component: () => import('@/components/user/ProfilePage'),
            meta: {auth: true},
        },

        /**
         * products
         */
        {
            path: '/products', name: 'products.index',
            meta: {
                auth: true,
            },
            component: () => import('@/components/products/ProductPage'),
        },
        {
            path: '/products/create', name: 'products.create',
            meta: {
                auth: true,
            },
            component: () => import('@/components/products/forms/ProductForm'),
        },
        {
            path: '/products/:id/edit',
            name: 'products.edit',
            meta: {
                auth: true,
            },
            component: () => import('@/components/products/forms/ProductForm'),
        },

        /**
         * customers
         */
        {
            path: '/customers', name: 'customers.index',
            meta: {
                auth: true,
                perm_customers: true
            },
            component: () => import('@/components/customers/CustomerPage'),
        },
        {
            path: '/customers/create', name: 'customers.create',
            meta: {
                auth: true,
                perm_customers: true
            },
            component: () => import('@/components/customers/forms/CustomerForm'),
        },
        {
            path: '/customers/:id/edit',
            name: 'customers.edit',
            meta: {
                auth: true,
                perm_customers: true
            },
            component: () => import('@/components/customers/forms/CustomerForm'),
        },

        /**
         * users
         */
        {
            path: '/users', name: 'users.index',
            meta: {
                auth: true,
                perm_users: true,
            },
            component: () => import('@/components/users/UserPage'),
        },
        {
            path: '/users/create', name: 'users.create',
            meta: {
                auth: true,
                perm_users: true,
            },
            component: () => import('@/components/users/forms/UserForm'),
        },
        {
            path: '/users/:id/edit',
            name: 'users.edit',
            meta: {
                auth: true,
                perm_users: true,
            },
            component: () => import('@/components/users/forms/UserForm'),
        },

        /**
         * companies
         */
        {
            path: '/companies', name: 'companies.index',
            meta: {
                auth: true,
                perm_companies: true,
            },
            component: () => import('@/components/companies/CompanyPage'),
        },
        {
            path: '/companies/create', name: 'companies.create',
            meta: {
                auth: true,
                perm_companies: true,
            },
            component: () => import('@/components/companies/forms/CompanyForm'),
        },
        {
            path: '/companies/:id/edit',
            name: 'companies.edit',
            meta: {
                auth: true,
                perm_companies: true,
            },
            component: () => import('@/components/companies/forms/CompanyForm'),
        },

        /**
         * checkout
         */
        {
            path: '/checkout', name: 'checkout.index',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/CheckoutPage'),
        },
        {
            path: '/checkout/:id/edit',
            name: 'checkout.edit',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/CheckoutSchedulePage'),
        },
        {
            path: '/checkout/finish', name: 'checkout.finish',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/forms/CheckoutFinishForm'),
        },
        {
            path: '/checkout/:id/finish',
            name: 'checkout.edit.finish',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/forms/CheckoutFinishForm'),
        },

        {
            path: '/queues', name: 'queues.index',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/QueuePage'),
        },
        {
            path: '/schedules', name: 'schedules.index',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/checkout/SchedulePage'),
        },

        {
            path: '/agendamento-cliente', name: 'client-schedules.index',
            meta: {
                auth: false,
            },
            component: () => import('@/components/schedules/ClientSchedulePage'),
        },
        {
            path: '/consulta-saldo', name: 'customer-balance.index',
            meta: {
                auth: false,
            },
            component: () => import('@/components/customers/CustomerBalancePage'),
        },

        /**
         * purchases
         */
        {
            path: '/purchases', name: 'purchases.index',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/purchases/PurchasePage'),
        },
        {
            path: '/purchases-view', name: 'purchases.view',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/purchases/PurchaseViewPage'),
        },
        {
            path: '/purchases/create', name: 'purchases.create',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/purchases/forms/PurchaseForm'),
        },
        {
            path: '/purchases/:id/edit',
            name: 'purchases.edit',
            meta: {
                auth: true,
                perm_purchases: true
            },
            component: () => import('@/components/purchases/forms/PurchaseForm'),
        },


        {
            path: '/analytics', name: 'analytics.index',
            meta: {
                auth: true,
                perm_statistics: true
            },
            component: () => import('@/components/analytics/AnalyticsPage'),
        },


        /**
         * config
         */
        {
            path: '/config', name: 'config.index',
            meta: {
                auth: true,
                perm_configs: true,
            },
            component: () => import('@/components/config/forms/ConfigForm'),
        },

        /**
         * supply orders
         */
        {
            path: '/supply-orders', name: 'supply-orders.index',
            meta: {
                auth: true,
                perm_supply_orders: true,
            },
            component: () => import('@/components/supply-orders/SupplyOrderPage'),
        },
        {
            path: '/supply-orders/create',
            name: 'supply-orders.create',
            meta: {
                auth: true,
                perm_supply_orders: true,
            },
            component: () => import('@/components/supply-orders/CheckoutSupplyOrderPage'),
        },
        {
            path: '/supply-orders/finish', name: 'supply-orders.finish',
            meta: {
                auth: true,
                perm_supply_orders: true,
            },
            component: () => import('@/components/supply-orders/forms/SupplyOrderForm'),
        },
        {
            path: '/supply-orders/:id/edit',
            name: 'supply-orders.edit',
            meta: {
                auth: true,
                perm_supply_orders: true,
            },
            component: () => import('@/components/supply-orders/forms/SupplyOrderForm'),
        },

        /**
         * slips
         */
        {
            path: '/slips', name: 'slips.index',
            meta: {
                auth: true,
                perm_slips: true,
            },
            component: () => import('@/components/slips/SlipPage'),
        },

        /**
         * communications
         */
        {
            path: '/communications', name: 'communications.index',
            meta: {
                auth: true,
                perm_communications: true,
            },
            component: () => import('@/components/communications/CommunicationPage'),
        },
        {
            path: '/communications/create', name: 'communications.create',
            meta: {
                auth: true,
                perm_communications: true,
            },
            component: () => import('@/components/communications/forms/CommunicationForm'),
        },
        {
            path: '/communications/:id/edit',
            name: 'communications.edit',
            meta: {
                auth: true,
                perm_communications: true,
            },
            component: () => import('@/components/communications/forms/CommunicationForm'),
        },

        /**
         * tickets
         */
        {
            path: '/tickets', name: 'tickets.index',
            meta: {
                auth: true,
                perm_tickets: true,
            },
            component: () => import('@/components/tickets/TicketPage'),
        },
        {
            path: '/tickets/create', name: 'tickets.create',
            meta: {
                auth: true,
                perm_tickets: true,
            },
            component: () => import('@/components/tickets/forms/TicketForm'),
        },
        {
            path: '/tickets/:id/edit',
            name: 'tickets.edit',
            meta: {
                auth: true,
                perm_tickets: true,
            },
            component: () => import('@/components/tickets/forms/TicketForm'),
        },

        /**
         * sales
         */
        {
            path: '/sale', name: 'sale.index',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/SalePage'),
        },
        {
            path: '/sale/finish', name: 'sale.finish',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/forms/SaleFinishForm'),
        },
        {
            path: '/sales', name: 'sales.index',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/SalesPage'),
        },
        {
            path: '/sales/:id/edit',
            name: 'sales.edit',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/forms/SalesForm'),
        },
        {
            path: '/sales-statistics', name: 'sales-statistics.index',
            meta: {
                auth: true,
                perm_sales_statistics: true
            },
            component: () => import('@/components/sales/StatisticsPage'),
        },
        {
            path: '/sales-draft', name: 'sales-draft.index',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/SaleDraftListPage'),
        },
        {
            path: '/sale/:id/draft',
            name: 'sale.draft',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/SaleDraftPage'),
        },
        {
            path: '/sale/:id/finish',
            name: 'sale.edit.finish',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/sales/forms/SaleFinishForm'),
        },

        /**
         * cashiers
         */
        {
            path: '/cashiers', name: 'cashiers.index',
            meta: {
                auth: true,
                perm_sales: true,
            },
            component: () => import('@/components/cashiers/CashierPage'),
        },
        {
            path: '/cashiers/:id/view',
            name: 'cashiers.view',
            meta: {
                auth: true,
                perm_sales: true
            },
            component: () => import('@/components/cashiers/forms/CashierForm'),
        },

        /**
         * employees
         */
        {
            path: '/employees', name: 'employees.index',
            meta: {
                auth: true,
                perm_employees: true
            },
            component: () => import('@/components/employees/EmployeePage'),
        },
        {
            path: '/employees/create', name: 'employees.create',
            meta: {
                auth: true,
                perm_employees: true
            },
            component: () => import('@/components/employees/forms/EmployeeForm'),
        },
        {
            path: '/employees/:id/edit',
            name: 'employees.edit',
            meta: {
                auth: true,
                perm_employees: true
            },
            component: () => import('@/components/employees/forms/EmployeeForm'),
        },

        /**
         * suppliers
         */
        {
            path: '/suppliers', name: 'suppliers.index',
            meta: {
                auth: true,
                perm_suppliers: true
            },
            component: () => import('@/components/suppliers/SupplierPage'),
        },
        {
            path: '/suppliers/create', name: 'suppliers.create',
            meta: {
                auth: true,
                perm_suppliers: true
            },
            component: () => import('@/components/suppliers/forms/SupplierForm'),
        },
        {
            path: '/suppliers/:id/edit',
            name: 'suppliers.edit',
            meta: {
                auth: true,
                perm_suppliers: true
            },
            component: () => import('@/components/suppliers/forms/SupplierForm'),
        },

        /**
         * account-plans
         */
        {
            path: '/account-plans', name: 'account-plans.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/account-plans/AccountPlanPage'),
        },
        {
            path: '/account-plans/create', name: 'account-plans.create',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/account-plans/forms/AccountPlanForm'),
        },
        {
            path: '/account-plans/:id/edit',
            name: 'account-plans.edit',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/account-plans/forms/AccountPlanForm'),
        },

        /**
         * bank-accounts
         */
        {
            path: '/bank-accounts', name: 'bank-accounts.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/bank-accounts/BankAccountPage'),
        },
        {
            path: '/bank-accounts/create', name: 'bank-accounts.create',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/bank-accounts/forms/BankAccountForm'),
        },
        {
            path: '/bank-accounts/:id/edit',
            name: 'bank-accounts.edit',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/bank-accounts/forms/BankAccountForm'),
        },

        /**
         * accounts-payable
         */
        {
            path: '/accounts-payable', name: 'accounts-payable.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-payable/AccountPayablePage'),
        },
        {
            path: '/accounts-payable/create', name: 'accounts-payable.create',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-payable/forms/AccountPayableForm'),
        },
        {
            path: '/accounts-payable/:id/edit',
            name: 'accounts-payable.edit',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-payable/forms/AccountPayableForm'),
        },

        /**
         * accounts-receivable
         */
        {
            path: '/accounts-receivable', name: 'accounts-receivable.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-receivable/AccountReceivablePage'),
        },
        {
            path: '/accounts-receivable/create', name: 'accounts-receivable.create',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-receivable/forms/AccountReceivableForm'),
        },
        {
            path: '/accounts-receivable/:id/edit',
            name: 'accounts-receivable.edit',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/accounts-receivable/forms/AccountReceivableForm'),
        },

        /**
         * cash-flow
         */
        {
            path: '/daily-cash-flow', name: 'daily-cash-flow.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/cash-flow/DailyCashFlowPage'),
        },
        {
            path: '/monthly-cash-flow', name: 'monthly-cash-flow.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/cash-flow/MonthlyCashFlowPage'),
        },

        /**
         * franchise-cashier-movements
         */
        {
            path: '/franchise-cashier-movements', name: 'franchise-cashier-movements.index',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/franchise-cashier/FranchiseCashierMovementPage'),
        },

        /**
         * bank-account-movements
         */
        {
            path: '/bank-account/:id/movements',
            name: 'bank-account.movements',
            meta: {
                auth: true,
                perm_financial: true
            },
            component: () => import('@/components/bank-account-movement/BankAccountMovementPage'),
        },

    ]
})
