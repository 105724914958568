import {AccountPlans} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAccountPlans(context, pagination) {
        return AccountPlans.getAccountPlans(pagination).then((response) => {
            return response.data;
        });
    },
    getAccountPlan(context, id) {
        return AccountPlans.get({id}).then((response) => {
            return response.data;
        });
    },
    createAccountPlan(context, item) {
        return AccountPlans.create(item).then((response) => {
            return response.data;
        });
    },
    updateAccountPlan(context, item) {
        return AccountPlans.update(item).then((response) => {
            return response.data;
        });
    },
    destroyAccountPlan(context, item) {
        return AccountPlans.destroy(item).then((response) => {
            return response.data;
        });
    },
    getAccountPlanField(context, item) {
        return AccountPlans.getAccountPlanField(item).then((response) => {
            return response.data;
        });
    },
    getAccountPlanItemField(context, item) {
        return AccountPlans.getAccountPlanItemField(item).then((response) => {
            return response.data;
        });
    },
    getDefinitionAccountPlan(context, item) {
        return AccountPlans.getDefinitionAccountPlan(item).then((response) => {
            return response.data;
        });
    },
    updateDefinitionAccountPlan(context, item) {
        return AccountPlans.updateDefinitionAccountPlan(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
