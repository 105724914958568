import {Communications} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCommunications(context, pagination) {
        return Communications.getCommunications(pagination).then((response) => {
            return response.data;
        });
    },
    getCommunication(context, id) {
        return Communications.get({id}).then((response) => {
            return response.data;
        });
    },
    createCommunication(context, item) {
        return Communications.create(item).then((response) => {
            return response.data;
        });
    },
    updateCommunication(context, item) {
        return Communications.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCommunication(context, item) {
        return Communications.destroy(item).then((response) => {
            return response.data;
        });
    },
    getCompanyCommunication(context, item) {
        return Communications.getCompanyCommunication(item).then((response) => {
            return response.data;
        });
    },
    setCompanyCommunication(context, item) {
        return Communications.setCompanyCommunication(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
