import {BankAccountDates} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getBankAccountDateMovements(context, pagination) {
        return BankAccountDates.getBankAccountDateMovements(pagination).then((response) => {
            return response.data;
        });
    },
    createBankAccountDate(context, item) {
        return BankAccountDates.create(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
