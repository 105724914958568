import {Users} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getUsers(context, pagination) {
        return Users.getUsers(pagination).then((response) => {
            return response.data;
        });
    },
    getUser(context, id) {
        return Users.get({id}).then((response) => {
            return response.data;
        });
    },
    createUser(context, item) {
        return Users.create(item).then((response) => {
            return response.data;
        });
    },
    updateUser(context, item) {
        return Users.update(item).then((response) => {
            return response.data;
        });
    },
    destroyUser(context, item) {
        return Users.destroy(item).then((response) => {
            return response.data;
        });
    },
    getUserField(context, item) {
        return Users.getUserField(item).then((response) => {
            return response.data;
        });
    },
    getUserBadge(context, item) {
        return Users.getUserBadge(item).then((response) => {
            return response.data;
        });
    },
    getUserNotifications(context, item) {
        return Users.getUserNotifications(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
