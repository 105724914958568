import {AccountsPayable} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getAccountsPayable(context, pagination) {
        return AccountsPayable.getAccountsPayable(pagination).then((response) => {
            return response.data;
        });
    },
    getAccountPayable(context, id) {
        return AccountsPayable.get({id}).then((response) => {
            return response.data;
        });
    },
    createAccountPayable(context, item) {
        return AccountsPayable.create(item).then((response) => {
            return response.data;
        });
    },
    updateAccountPayable(context, item) {
        return AccountsPayable.update(item).then((response) => {
            return response.data;
        });
    },
    destroyAccountPayable(context, item) {
        return AccountsPayable.destroy(item).then((response) => {
            return response.data;
        });
    },
    paymentAccountPayable(context, item) {
        return AccountsPayable.paymentAccountPayable(item).then((response) => {
            return response.data;
        });
    },
    cancelAccountPayable(context, item) {
        return AccountsPayable.cancelAccountPayable(item).then((response) => {
            return response.data;
        });
    },
    reverseAccountPayable(context, item) {
        return AccountsPayable.reverseAccountPayable(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
