import {Duty} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getDuties(context, pagination) {
        return Duty.getDuties(pagination).then((response) => {
            return response.data;
        });
    },
    getDuty(context, id) {
        return Duty.get({id}).then((response) => {
            return response.data;
        });
    },
    createDuty(context, item) {
        return Duty.create(item).then((response) => {
            return response.data;
        });
    },
    updateDuty(context, item) {
        return Duty.update(item).then((response) => {
            return response.data;
        });
    },
    destroyDuty(context, item) {
        return Duty.destroy(item).then((response) => {
            return response.data;
        });
    },
    getDutyField(context, item) {
        return Duty.getDutyField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
