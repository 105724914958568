import Vue from 'vue'
import App from './App'
import Vuetify from 'vuetify'
import VeeValidate, {Validator} from 'vee-validate'
import pt_BR from 'vee-validate/dist/locale/pt_BR'
import store from './store/store'
import router from './router'
import colors from 'vuetify/es5/util/colors'
import './filter'
import 'vuetify/dist/vuetify.min.css'
import vuetifyMoney from "./services/VuetifyMoney.vue";

Vue.use(Vuetify, {
    theme: {
        primary: colors.pink.lighten2,
        secondary: colors.pink.lighten2,
        accent: colors.pink.base
    }
});
Vue.use(VeeValidate);

Vue.component("vuetify-money", vuetifyMoney);

Validator.localize('pt_BR', pt_BR);

Vue.http.options.root = process.env.VUE_APP_URL_API;
Vue.config.productionTip = false;

Vue.prototype.$formatStorage = function (value) {
    const urlRegex = /^(http(s)?:\/\/)?(www\.)?([\w-]+\.)+[\w]+(\/[\w- ./?%&=]*)?$/;

    if (value) {
        return urlRegex.test(value) ? value : `${this.$http.options.root}/storage/${value}`;
    } else {
        return value;
    }
};

router.beforeEach((to, from, next) => {
    to.matched.some(route => {
        let authenticated = store.state.auth.check;
        let user = store.state.auth.user;

        if (authenticated) {
            let defaultPage = 'dashboard';

            if (defaultPage !== route.name) {
                if (route.meta.auth) {
                    if (
                        route.meta.perm_products && !parseInt(user.perm_products) ||
                        route.meta.perm_users && !parseInt(user.perm_users) ||
                        route.meta.perm_statistics && !parseInt(user.perm_statistics) ||
                        route.meta.perm_companies && !parseInt(user.perm_companies) ||
                        route.meta.perm_configs && !parseInt(user.perm_configs) ||
                        route.meta.perm_supply_orders && !parseInt(user.perm_supply_orders) ||
                        route.meta.perm_slips && !parseInt(user.perm_slips) ||
                        route.meta.perm_communications && !parseInt(user.perm_communications) ||
                        route.meta.perm_tickets && !parseInt(user.perm_tickets) ||
                        route.meta.perm_employees && !parseInt(user.perm_employees) ||
                        route.meta.perm_purchases && !parseInt(user.perm_purchases) ||
                        route.meta.perm_sales && !parseInt(user.perm_sales) ||
                        route.meta.perm_sales_statistics && !parseInt(user.perm_sales_statistics) ||
                        route.meta.perm_customers && !parseInt(user.perm_customers) ||
                        route.meta.perm_suppliers && !parseInt(user.perm_suppliers) ||
                        route.meta.perm_financial && !parseInt(user.perm_financial)
                    ) {
                        return router.push({name: 'dashboard'});
                    }
                } else {
                    return router.push({name: defaultPage});
                }
            }
        } else {
            if (route.meta.auth) {
                return router.push({name: 'login'});
            }
        }

        next()
    });
});

new Vue({
    el: '#app',
    router,
    components: {App},
    template: '<App/>',
});