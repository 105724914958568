import Vue from 'vue'

Vue.filter('filterDate', function (element) {
    if (element) {
        element = element.split(' ');
        if (element) {
            return element[0].split('-').reverse().join("/");
        }
    }
});

Vue.filter('filterValue', function (value) {
    value = parseFloat(value);

    let response = value
        .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

    return (response === 'R$ NaN' || response == 'NaN') ? '0,00' : response;
});

Vue.filter('filterPhone', function (element) {
    if (element) {
        const onlyNumbers = element.replace(/[^0-9]/g, '');
        const tam = onlyNumbers.length;

        if (tam === 13) {
            return `+${onlyNumbers.slice(0, tam - 11)} (${onlyNumbers.slice(tam - 11, tam - 9)}) ${onlyNumbers.slice(tam - 9, tam - 4)}-${onlyNumbers.slice(-4)}`;
        }
        if (tam === 12) {
            return `+${onlyNumbers.slice(0, tam - 10)} (${onlyNumbers.slice(tam - 10, tam - 8)}) ${onlyNumbers.slice(tam - 8, tam - 4)}-${onlyNumbers.slice(-4)}`;
        }
        if (tam === 11) {
            return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2, 7)}-${onlyNumbers.slice(7)}`;
        }
        if (tam === 10) {
            return `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2, 6)}-${onlyNumbers.slice(6)}`;
        }
        if (tam <= 9) {
            return `${onlyNumbers.slice(0, tam - 4)}-${onlyNumbers.slice(-4)}`;
        }
    }
});

Vue.filter("filterCNPJ", (element) => {
    if (element) {
        return element
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    }
});

Vue.filter("filterCPF", (element) => {
    if (element) {
        const cnpjCpf = element.replace(/\D/g, '');

        if (cnpjCpf.length === 11) {
            return cnpjCpf
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        }

        return cnpjCpf
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '$1.$2')
            .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            .replace(/\.(\d{3})(\d)/, '.$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2');
    }
});

Vue.filter("filterCEP", (element) => {
    if (element) {
        return element
            .replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2');
    }
});