import {CashFlow} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getDailyCashFlow(context, item) {
        return CashFlow.getDailyCashFlow(item).then((response) => {
            return response.data;
        });
    },
    showDailyCashFlow(context, item) {
        return CashFlow.showDailyCashFlow(item).then((response) => {
            return response.data;
        });
    },
    getMonthlyCashFlow(context, item) {
        return CashFlow.getMonthlyCashFlow(item).then((response) => {
            return response.data;
        });
    },
    showMonthlyCashFlow(context, item) {
        return CashFlow.showMonthlyCashFlow(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
