import {SupplyOrders} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSupplyOrders(context, pagination) {
        return SupplyOrders.getSupplyOrders(pagination).then((response) => {
            return response.data;
        });
    },
    getSupplyOrder(context, id) {
        return SupplyOrders.get({id}).then((response) => {
            return response.data;
        });
    },
    createSupplyOrder(context, item) {
        return SupplyOrders.create(item).then((response) => {
            return response.data;
        });
    },
    updateSupplyOrder(context, item) {
        return SupplyOrders.update(item).then((response) => {
            return response.data;
        });
    },
    destroySupplyOrder(context, item) {
        return SupplyOrders.destroy(item).then((response) => {
            return response.data;
        });
    },
    cancelSupplyOrder(context, item) {
        return SupplyOrders.cancelSupplyOrder(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
