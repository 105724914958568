import {NFeSupply} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getNFeSupply(context, id) {
        return NFeSupply.get({id}).then((response) => {
            return response.data;
        });
    },
    updateNFeSupply(context, item) {
        return NFeSupply.update(item).then((response) => {
            return response.data;
        });
    },
    generateDANFeSupply(context, item) {
        return NFeSupply.generateDANFeSupply(item).then((response) => {
            return response.data;
        });
    },
    issueNFeSupply(context, item) {
        return NFeSupply.issueNFeSupply(item).then((response) => {
            return response.data;
        });
    },
    cancelNFeSupply(context, item) {
        return NFeSupply.cancelNFeSupply(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
