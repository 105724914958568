import {Customers} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCustomers(context, pagination) {
        return Customers.getCustomers(pagination).then((response) => {
            return response.data;
        });
    },
    getCustomer(context, id) {
        return Customers.get({id}).then((response) => {
            return response.data;
        });
    },
    createCustomer(context, item) {
        return Customers.create(item).then((response) => {
            return response.data;
        });
    },
    updateCustomer(context, item) {
        return Customers.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCustomer(context, item) {
        return Customers.destroy(item).then((response) => {
            return response.data;
        });
    },
    getCustomerField(context, item) {
        return Customers.getCustomerField(item).then((response) => {
            return response.data;
        });
    },
    getCustomerBalanceHistories(context, item) {
        return Customers.getCustomerBalanceHistories(item).then((response) => {
            return response.data;
        });
    },
    updateCustomerBalance(context, item) {
        return Customers.updateCustomerBalance(item).then((response) => {
            return response.data;
        });
    },

    createCustomerPublic(context, item) {
        return Customers.createCustomerPublic(item).then((response) => {
            return response.data;
        });
    },
    checkCustomerPublic(context, item) {
        return Customers.checkCustomerPublic(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
