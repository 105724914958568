import {Suppliers} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getSuppliers(context, pagination) {
        return Suppliers.getSuppliers(pagination).then((response) => {
            return response.data;
        });
    },
    getSupplier(context, id) {
        return Suppliers.get({id}).then((response) => {
            return response.data;
        });
    },
    createSupplier(context, item) {
        return Suppliers.create(item).then((response) => {
            return response.data;
        });
    },
    updateSupplier(context, item) {
        return Suppliers.update(item).then((response) => {
            return response.data;
        });
    },
    destroySupplier(context, item) {
        return Suppliers.destroy(item).then((response) => {
            return response.data;
        });
    },
    getSupplierField(context, item) {
        return Suppliers.getSupplierField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
