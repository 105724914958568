import {Purchases} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getPurchases(context, pagination) {
        return Purchases.getPurchases(pagination).then((response) => {
            return response.data;
        });
    },
    getPurchase(context, id) {
        return Purchases.get({id}).then((response) => {
            return response.data;
        });
    },
    createPurchase(context, item) {
        return Purchases.create(item).then((response) => {
            return response.data;
        });
    },
    updatePurchase(context, item) {
        return Purchases.update(item).then((response) => {
            return response.data;
        });
    },
    // destroyPurchase(context, item) {
    //     return Purchases.destroy(item).then((response) => {
    //         return response.data;
    //     });
    // },
    getPurchaseStatistics(context, item) {
        return Purchases.getPurchaseStatistics(item).then((response) => {
            return response.data;
        });
    },
    sendEmailReceiptPurchase(context, item) {
        return Purchases.sendEmailReceiptPurchase(item).then((response) => {
            return response.data;
        });
    },
    cancelPurchase(context, item) {
        return Purchases.cancelPurchase(item).then((response) => {
            return response.data;
        });
    },
    signPurchase(context, item) {
        return Purchases.signPurchase(item).then((response) => {
            return response.data;
        });
    },
    changePaymentMethods(context, item) {
        return Purchases.changePaymentMethods(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
