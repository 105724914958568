import {NFSe} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getNFSe(context, id) {
        return NFSe.get({id}).then((response) => {
            return response.data;
        });
    },
    updateNFSe(context, item) {
        return NFSe.update(item).then((response) => {
            return response.data;
        });
    },
    generateDANFSe(context, item) {
        return NFSe.generateDANFSe(item).then((response) => {
            return response.data;
        });
    },
    issueNFSe(context, item) {
        return NFSe.issueNFSe(item).then((response) => {
            return response.data;
        });
    },
    cancelNFSe(context, item) {
        return NFSe.cancelNFSe(item).then((response) => {
            return response.data;
        });
    },
    getLayoutNFSeField(context, item) {
        return NFSe.getLayoutNFSeField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
