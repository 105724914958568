import {Category} from '../services/resources';

const state = {};

const mutations = {};

const actions = {
    getCategories(context, pagination) {
        return Category.getCategories(pagination).then((response) => {
            return response.data;
        });
    },
    getCategory(context, id) {
        return Category.get({id}).then((response) => {
            return response.data;
        });
    },
    createCategory(context, item) {
        return Category.create(item).then((response) => {
            return response.data;
        });
    },
    updateCategory(context, item) {
        return Category.update(item).then((response) => {
            return response.data;
        });
    },
    destroyCategory(context, item) {
        return Category.destroy(item).then((response) => {
            return response.data;
        });
    },
    getCategoryField(context, item) {
        return Category.getCategoryField(item).then((response) => {
            return response.data;
        });
    },
};

const module = {
    state,
    mutations,
    actions
};

export default module;
