<template>
  <v-navigation-drawer :mini-variant="miniVariant" ref="drawer"
                       app
                       style="background-color: rgb(33 33 33 / 99%)"
                       dark
                       fixed
                       persistent
                       v-if="authenticated"
                       v-model="drawer"
                       width="279">
    <v-img height="200px" contain v-show="!miniVariant" @click="$router.push({name: 'dashboard'})"
           style="cursor: pointer;"
           :src="require(`../../assets/logo.png`)">
      <v-layout align-end justify-center row fill-height v-if="company && company.id">
        <v-chip label small color="primary">
          <strong>
            {{ company.referencia }}
          </strong>
        </v-chip>
      </v-layout>
    </v-img>

    <v-toolbar class="transparent" flat>
      <v-list :class="{'list-border-bottom' : miniVariant}" class="pa-0">
        <v-list-tile>
          <v-list-tile-content v-if="!miniVariant">
            <v-spacer></v-spacer>

            <v-flex shrink>
              <div class="subheading">{{ user.name.slice(0, 16) }}</div>
              <div class="body-1 primary--text text--lighten-2">
                <small>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        Maior Franquia de Brechó Feminino
                      </div>
                    </template>
                    <span>
                       Versão <strong>{{ version }}</strong>
                    </span>
                  </v-tooltip>
                </small>
              </div>
            </v-flex>

          </v-list-tile-content>
          <v-list-tile-action style="margin-right: -10px;">
            <v-btn @click.stop="miniVariant = !miniVariant" icon>
              <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-divider v-if="List1.length"></v-divider>
    <v-list subheader v-if="List1.length">
      <v-subheader>Compras</v-subheader>
      <template v-for="(item, index) in List1">
        <div v-if="miniVariant" :key="index">
          <v-tooltip :disabled="!miniVariant" right :key="index">
            <v-list-tile :key="item.icon"
                         :to="item.link ? {name: item.link} : null"
                         exact
                         slot="activator">
              <v-list-tile-action>
                <div v-if="item.badge">
                  <v-badge color="red">
                    <template v-slot:badge>
                      <small>{{ item.badge }}</small>
                    </template>
                    <v-icon v-html="item.icon"></v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon v-html="item.icon"></v-icon>
                </div>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
        <div v-else :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <div v-if="item.badge">
                <v-badge color="red">
                  <template v-slot:badge>
                    <small>{{ item.badge }}</small>
                  </template>
                  <v-icon v-html="item.icon"></v-icon>
                </v-badge>
              </div>
              <div v-else>
                <v-icon v-html="item.icon"></v-icon>
              </div>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </template>
    </v-list>

    <v-divider v-if="List2.length"></v-divider>
    <v-list subheader v-if="List2.length">
      <v-subheader>Vendas</v-subheader>
      <template v-for="(item, index) in List2">
        <div v-if="miniVariant" :key="index">
          <v-tooltip :disabled="!miniVariant" right :key="index">
            <v-list-tile :key="item.icon"
                         :to="item.link ? {name: item.link} : null"
                         exact
                         slot="activator">
              <v-list-tile-action>
                <div v-if="item.badge">
                  <v-badge color="red">
                    <template v-slot:badge>
                      <small>{{ item.badge }}</small>
                    </template>
                    <v-icon v-html="item.icon"></v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon v-html="item.icon"></v-icon>
                </div>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
        <div v-else :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <div v-if="item.badge">
                <v-badge color="red">
                  <template v-slot:badge>
                    <small>{{ item.badge }}</small>
                  </template>
                  <v-icon v-html="item.icon"></v-icon>
                </v-badge>
              </div>
              <div v-else>
                <v-icon v-html="item.icon"></v-icon>
              </div>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </template>
    </v-list>

    <v-divider v-if="List3.length"></v-divider>
    <v-list subheader v-if="List3.length">
      <v-subheader>Cadastros</v-subheader>
      <template v-for="(item, index) in List3">
        <div v-if="miniVariant" :key="index">
          <v-tooltip :disabled="!miniVariant" right :key="index">
            <v-list-tile :key="item.icon"
                         :to="item.link ? {name: item.link} : null"
                         exact
                         slot="activator">
              <v-list-tile-action>
                <v-icon v-html="item.icon"></v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
        <div v-else :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </template>
    </v-list>

    <v-divider v-if="List4.length"></v-divider>
    <v-list subheader v-if="List4.length">
      <v-subheader class="grey--text">Financeiro</v-subheader>
      <template v-for="(item, index) in List4">
        <div v-if="miniVariant" :key="index">
          <v-tooltip :disabled="!miniVariant" right :key="index">
            <v-list-tile :key="item.icon"
                         :to="item.link ? {name: item.link} : null"
                         exact
                         slot="activator">
              <v-list-tile-action>
                <v-icon v-html="item.icon"></v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
        <div v-else :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </template>
    </v-list>

    <v-divider v-if="List5.length"></v-divider>
    <v-list subheader v-if="List5.length">
      <v-subheader>Gestão</v-subheader>
      <template v-for="(item, index) in List5">
        <div v-if="miniVariant" :key="index">
          <v-tooltip :disabled="!miniVariant" right :key="index">
            <v-list-tile :key="item.icon"
                         :to="item.link ? {name: item.link} : null"
                         exact
                         slot="activator">
              <v-list-tile-action>
                <div v-if="item.badge">
                  <v-badge color="red">
                    <template v-slot:badge>
                      <small>{{ item.badge }}</small>
                    </template>
                    <v-icon v-html="item.icon"></v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon v-html="item.icon"></v-icon>
                </div>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title v-text="item.title"></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
        <div v-else :key="index">
          <v-list-tile :key="item.icon"
                       :to="item.link ? {name: item.link} : null"
                       exact
                       slot="activator">
            <v-list-tile-action>
              <div v-if="item.badge">
                <v-badge color="red">
                  <template v-slot:badge>
                    <small>{{ item.badge }}</small>
                  </template>
                  <v-icon v-html="item.icon"></v-icon>
                </v-badge>
              </div>
              <div v-else>
                <v-icon v-html="item.icon"></v-icon>
              </div>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.title"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: [
    'authenticated',
    'user',
    'company'
  ],
  data() {
    return {
      version: process.env.VUE_APP_VERSION,

      drawer: true,
      miniVariant: false,
      isFooterFixed: false,

      List1: [],
      List2: [],
      List3: [],
      List4: [],
      List5: [],
    }
  },
  watch: {
    authenticated(auth) {
      if (auth) {
        setTimeout(() => {
          if (this.$refs.drawer) {
            this.$refs.drawer.$el.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleScroll);
            this.handleScroll()
          }
        }, 200);
      } else {
        if (this.$refs.drawer) {
          this.$refs.drawer.$el.removeEventListener('scroll', this.handleScroll);
          window.removeEventListener('resize', this.handleScroll);
        }
      }
    },
    miniVariant() {
      this.handleScroll();
    },
    '$route': function (next, previous) {
      let variants = [
        'checkout.index',
        'checkout.edit',
        'supply-orders.create',
        'tickets.create',
        'tickets.edit',
        'sale.index',
        'sale.draft',
        'sale.finish',
        'sale.edit.finish',
        'monthly-cash-flow.index',
      ];

      let variant = variants.includes(next.name);

      if (variants.includes(previous.name) && variants.includes(next.name)) {
        variant = true;
      }

      this.miniVariant = variant;
    },
    user() {
      this.menu();
    }
  },
  mounted() {
    if (this.$refs.drawer) {
      this.$refs.drawer.$el.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleScroll);
      this.handleScroll()
    }
  },
  beforeDestroy() {
    if (this.$refs.drawer) {
      this.$refs.drawer.$el.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      // if (this.miniVariant) {
      //   this.isFooterFixed = true;
      //   return;
      // }

      if (this.$refs.drawer) {
        if (this.$refs.drawer.$el.scrollHeight == this.$refs.drawer.$el.offsetHeight) {
          this.isFooterFixed = true;
        } else {
          this.isFooterFixed = false;
        }
      }
    },
    menu() {
      Object.assign(this.$data, this.$options.data.call(this));

      // List1
      if (this.user.perm_purchases) {
        this.List1.push({
          icon: 'shopping_cart',
          title: 'Comprar',
          link: 'checkout.index',
        });
        this.List1.push({
          icon: 'pending_actions',
          title: 'Fila loja física',
          link: 'queues.index',
          badge: 0
        });
        this.List1.push({
          icon: 'event_note',
          title: 'Agendamentos',
          link: 'schedules.index',
          badge: 0
        });
        this.List1.push({
          icon: 'attach_money',
          title: 'Histórico',
          link: 'purchases.index',
        });
      }
      if (this.user.perm_statistics) {
        this.List1.push({
          icon: 'query_stats',
          title: 'Estatísticas',
          link: 'analytics.index',
        });
      }

      // List2
      if (this.user.perm_sales) {
        this.List2.push({
          icon: 'point_of_sale',
          title: 'Vender',
          link: 'sale.index',
        });
        this.List2.push({
          icon: 'draw',
          title: 'Rascunhos',
          link: 'sales-draft.index',
        });
        this.List2.push({
          icon: 'attach_money',
          title: 'Histórico',
          link: 'sales.index',
        });
        this.List2.push({
          icon: 'account_tree',
          title: 'Caixas',
          link: 'cashiers.index',
        });
      }

      if (this.user.perm_sales_statistics) {
        this.List2.push({
          icon: 'query_stats',
          title: 'Estatísticas',
          link: 'sales-statistics.index',
        });
      }

      // List3
      if (this.user.perm_customers) {
        this.List3.push({
          icon: 'badge',
          title: 'Clientes',
          link: 'customers.index',
        });
      }
      if (this.user.perm_products) {
        this.List3.push({
          icon: 'conveyor_belt',
          title: 'Produtos',
          link: 'products.index',
        });
      }
      if (this.user.perm_suppliers) {
        this.List3.push({
          icon: 'corporate_fare',
          title: 'Fornecedores',
          link: 'suppliers.index',
        });
      }

      // List4
      if (this.user.perm_financial) {
        this.List4.push({
          icon: 'savings',
          title: 'Caixa Tesouraria',
          link: 'franchise-cashier-movements.index',
        });

        this.List4.push({
          icon: 'money_off',
          title: 'Contas a Pagar',
          link: 'accounts-payable.index',
        });

        this.List4.push({
          icon: 'attach_money',
          title: 'Contas a Receber',
          link: 'accounts-receivable.index',
        });

        this.List4.push({
          icon: 'account_balance',
          title: 'Contas Bancárias',
          link: 'bank-accounts.index',
        });

        this.List4.push({
          icon: 'bar_chart',
          title: 'Fluxo Diário',
          link: 'daily-cash-flow.index',
        });

        this.List4.push({
          icon: 'ssid_chart',
          title: 'Fluxo Mensal',
          link: 'monthly-cash-flow.index',
        });

        this.List4.push({
          icon: 'auto_awesome_motion',
          title: 'Planos de Conta',
          link: 'account-plans.index',
        });
      }

      // List5
      if (this.user.perm_companies) {
        this.List5.push({
          icon: 'add_business',
          title: 'Franquias',
          link: 'companies.index',
        });
      }

      if (this.user.perm_slips) {
        this.List5.push({
          icon: 'payments',
          title: 'Boletos',
          link: 'slips.index',
          badge: 0
        });
      }

      if (this.user.perm_supply_orders) {
        this.List5.push({
          icon: 'shopping_basket',
          title: 'Pedido de Insumos',
          link: 'supply-orders.index',
          badge: 0
        });
      }

      if (this.user.perm_communications) {
        this.List5.push({
          icon: 'inbox',
          title: 'Comunicados',
          link: 'communications.index',
        });
      }

      if (this.user.perm_tickets) {
        this.List5.push({
          icon: 'support_agent',
          title: 'SAF',
          link: 'tickets.index',
        });
      }

      if (this.user.perm_employees) {
        this.List5.push({
          icon: 'diversity_3',
          title: 'Funcionários',
          link: 'employees.index',
        });
      }

      if (this.user.perm_users) {
        this.List5.push({
          icon: 'switch_account',
          title: 'Usuários',
          link: 'users.index',
        });
      }

      if (this.user.perm_configs) {
        this.List5.push({
          icon: 'settings',
          title: 'Configurações',
          link: 'config.index',
        });
      }
    }
  },
  computed: {},
  created() {
    this.menu();
  }
}
</script>

<style scoped>
.v-navigation-drawer--mini-variant {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  border-left: 1px solid hsla(0, 0%, 100%, .12);
}

::-webkit-scrollbar * {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #B0BEC5 !important;
  border-radius: 10px;
}
</style>
